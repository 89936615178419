<template>
  <div class="page-wrapper">
    
    <header class="main-header always-sticky golf">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant">
        <img src="@/assets/img/tagline-fr.svg" v-if="$route.meta.lang === 'fr'" class="tagline" alt="Tagline Tremblant">
        <img src="@/assets/img/tagline-en.svg" v-else-if="$route.meta.lang === 'en'" class="tagline" alt="Tagline Tremblant">
      </div>
      <div class="right golf">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang">FR</router-link>
        </div>
        <a :href="$t('site_ete_2022.golf_link')" target="_blank" class="cta red2">{{ $t('site_ete_2022.golf_cta') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone3.svg" alt="Call Tremblant"></a>
      <div class="mobile-cta">
        <a :href="$t('site_ete_2022.golf_link')" target="_blank" class="cta red2">{{ $t('site_ete_2022.golf_cta') }}</a>
      </div>
    </header>

    <section class="terms-wrap golf">

      <div v-if="$route.meta.lang === 'fr'">

        <button @click.prevent="goback" class="cta prune">Retour</button><br><br>

        <h1 class="big-title">Modalités<br>Golf</h1>

        <h2 class="small-title">Validité</h2>

        <ul>
          <li class="regular-text">Les billets T-golf Le Diable sont valables en tout temps, 7 jours sur 7, durant la saison de golf 2022.</li>
          <li class="regular-text">Les billets Le Diable peuvent être utilisés par des personnes différentes, par la même personne pour des parties différentes, ou être offerts en cadeau.</li>
          <li class="regular-text">Les billets T-Golf Le Diable sont valables sur Le Diable et Le Géant. Aucune remise en argent ne sera offerte si le prix d’une ronde passante au Géant est moindre que le prix d’un billet T-Golf.</li>
          <li class="regular-text">L’accès au terrain d’exercice du golf Le Diable est gratuit pour toute personne ayant une réservation pour un départ sur Le Diable le jour même. L’accès au terrain d’exercice n’est pas disponible pour toute personne ayant une réservation pour un départ sur le Géant.</li>
        </ul>
        
        <h2 class="small-title">Achat et réservation</h2>
        <ul>
          <li class="regular-text">Achat en ligne ou par téléphone</li>
          <li class="regular-text">Les billets doivent être achetés 48 heures d’avance.</li>
          <li class="regular-text">Achat maximal de 19 billets par personne, par transaction.</li>
          <li class="regular-text">Achat minimum de 2 parties par transaction. </li>
          <li class="regular-text">Pour un événement de groupe, composez-le :</li>
          <ul>
            <li class="regular-text">1-888-633-7657 (avec hébergement)</li>
            <li class="regular-text">1-866-220-9022 (sans hébergement)</li>
          </ul>
        </ul>
        <h2 class="small-title">Réservation des heures de départ</h2>
        <ul>
          <li class="regular-text">Les heures de départ doivent être réservées en ligne ou par téléphone au 1-866-783-5634.</li>
          <li class="regular-text">La pénalité pour les absences équivaut au plein tarif pour la partie manquée.</li>
          <li class="regular-text">Voiturette incluse avec chaque départ</li>
        </ul>

        <h2 class="small-title">Livraison</h2>
        <ul>
          <li class="regular-text">Les billets doivent être récupérés à la boutique du golf Le Diable, au Service à la Clientèle ou à la billetterie.</li>
          <li class="regular-text">Si vous offrez les billets en cadeau, veuillez fournir le nom et l’adresse de la personne concernée afin qu’elle puisse récupérer ses billets dans l’un de nos points de service.</li>
        </ul>

        <h2 class="small-title">Remboursement</h2>
        <ul>
          <li class="regular-text">Non remboursables et valables pour la saison de golf 2022 seulement.</li>
          <li class="regular-text">Les rondes T-Golf achetées en 2021 et non-jouées peuvent être reportées à 2022 sans frais.</li>
          <li class="regular-text">Ronde expirée inutilisée? La valeur payée sera applicable sur l’achat d’une ronde au tarif passant.</li>
        </ul>

      </div>

      <div v-else-if="$route.meta.lang === 'en'">

        <button @click.prevent="goback" class="cta prune">Back</button><br><br>

        <h1 class="big-title">Golf<br>Terms & Conditions</h1>

        <h2 class="small-title">Validity</h2>
        <ul>
          <li class="regular-text">T-Golf Le Diable tickets are valid at all times, 7 days a week, during the 2022 golf season.</li>
          <li class="regular-text">Tickets can be used by different players, by the same player for different rounds or offered as a gift.</li>
          <li class="regular-text">T-Golf Le Diable tickets are valid on Le Diable golf course and Le Géant golf course. If there is a difference in price between a regular round at Le Géant and the value of a T-Golf ticket, it will not be refunded.</li>
          <li class="regular-text">Access to Le Diable golf course driving range is free of charge for any player with a tee time on Le Diable that same day. </li>
          <li class="regular-text">Players with a tee time on Le Géant do not have access to the driving range.</li>
        </ul>
        
        <h2 class="small-title">Purchase and Booking</h2>
        <ul>
          <li class="regular-text">Purchase online or by phone.</li>
          <li class="regular-text">Must be purchased at least 48 hours prior to tee time. </li>
          <li class="regular-text">Maximum purchase of 19 tickets per person, per transaction.</li>
          <li class="regular-text">Minimum purchase of two rounds per transaction.</li>
          <li class="regular-text">Planning a group event? Contact us at:</li>
          <ul>
            <li class="regular-text">1-888-633-7657 (with lodging)</li>
            <li class="regular-text">1-866-220-9022 (without lodging)</li>
          </ul>
        </ul>
        <h2 class="small-title">Tee Time Bookings</h2>
        <ul>
          <li class="regular-text">Tee times must be booked online or by phone at 1-866-783-5634.</li>
          <li class="regular-text">Bookings required and subject to availability.</li>
          <li class="regular-text">No-show penalty is equal to the regular rate of the missed round.</li>
          <li class="regular-text">Golf cart included with each round.</li>
        </ul>

        <h2 class="small-title">Delivery</h2>
        <ul>
          <li class="regular-text">Tickets must be picked up at Le Diable pro shop, our Guest Services Desk or ticket office.</li>
          <li class="regular-text">If you offer these tickets as a gift, please provide the name and address of the person who will be picking them up at one of our service desks.</li>
        </ul>

        <h2 class="small-title">Refund</h2>
        <ul>
          <li class="regular-text">Cannot be refunded and are valid for the 2022 golf season only.</li>
          <li class="regular-text">T-Golf Le Diable tickets bought in 2021 and unused can be transferred to 2022 at no fee. </li>
          <li class="regular-text">Expired and unused tickets: the prepaid value can be applied towards to purchase of a regular rate round.</li>
        </ul>

      </div>
      
    </section>

    <div class="overlay-form-loding" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform">
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              name="arrivaldate"
              v-model="time1"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'arrivaldate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              name="departuredate"
              v-model="time2"
              :lang="this.datepickerLang"
              :disabledDate="disabledBeforeToday"
              :inputAttr="{name: 'departuredate', required: 'required'}"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input
              name="adultcount"
              type="number"
              value="2"
              min="1"
              required
            >
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input
              name="childCount"
              type="number"
              value="0"
              required
            >
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')">
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'
import FooterBar from '@/components/FooterBar'

export default {
  components: { NewsLetter, FooterBar, DatePicker },
  head() {
    return {
      title: {
        inner: "Éclaté de haut en bas"
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `Tremblant - Offres` },
        {
          name: 'description',
          content: `Profitez des meilleures offres et prix de l'année.`,
          id: 'desc'
        },
        // Twitter OG
        { name: 'twitter:title', content: `Tremblant - Offres` },
        { name: 'twitter:card', content: 'summary' },
        {
          n: 'twitter:description',
          c: `Profitez des meilleures offres et prix de l'année.`
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `Tremblant - Offres` },
        { property: 'og:site_name', content: `Tremblant - Offres` },
        {
          property: 'og:description',
          content: `Profitez des meilleures offres et prix de l'année.`
        },
        // Og Image
        {
          p: 'og:image',
          c:
            ''
        },
        {
          name: 'twitter:image',
          content: ''
        }
      ]
    }
  },
  props: {
    pageData: {
      type: Object,
      required: true,
      default: null
    },
  },
  data() {
    return {
      toggle: true,
      formlodging: false,
      sidepanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      time1: new Date(),
      time2: null
    }
  },
  computed: mapState('app', ['appTitle']),
  methods: {
    goback() {
        return this.$router.go(-1)
    },
    table1() {
        this.toggle = true
    },
    table2() {
        this.toggle = false
    },
    showlodgingform() {
        if(this.formlodging) {
          this.formlodging = false
        } else {
          this.formlodging = true
        }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    showsidepanel() {
        if(this.sidepanel) {
          this.sidepanel = false
        } else {
          this.sidepanel = true
        }
    },
    hidesidepanel() {
      this.sidepanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    snowscroll() {
      const st = document.documentElement.scrollTop

      const element = this.$el.querySelector('.snow-background')
      const elementStyle = element.style

      element.style.top = `${ -150 + st /2}px`
    } 
  },
  mounted () {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },
  created() {
      window.addEventListener('scroll', () => {
        this.snowscroll()
      })
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
